/* ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ROOT */

:root {
}

/* ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== POSITION GRID */

@media( min-width:  576px ) { .k6-position-relative-sm  { position: relative !important; } .k6-sticky-top-sm  { position: -webkit-sticky; position: sticky; top: 0; z-index: 1020; } .k6-align-self-start-sm  { align-self: start; } }
@media( min-width:  768px ) { .k6-position-relative-md  { position: relative !important; } .k6-sticky-top-md  { position: -webkit-sticky; position: sticky; top: 0; z-index: 1020; } .k6-align-self-start-md  { align-self: start; } }
@media( min-width:  992px ) { .k6-position-relative-lg  { position: relative !important; } .k6-sticky-top-lg  { position: -webkit-sticky; position: sticky; top: 0; z-index: 1020; } .k6-align-self-start-lg  { align-self: start; } }
@media( min-width: 1200px ) { .k6-position-relative-xl  { position: relative !important; } .k6-sticky-top-xl  { position: -webkit-sticky; position: sticky; top: 0; z-index: 1020; } .k6-align-self-start-xl  { align-self: start; } }
@media( min-width: 1400px ) { .k6-position-relative-xxl { position: relative !important; } .k6-sticky-top-xxl { position: -webkit-sticky; position: sticky; top: 0; z-index: 1020; } .k6-align-self-start-xxl { align-self: start; } }

/* ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== DISPLAY VISIBILITY OVERFLOW */

@media( min-width:  576px ) { .k6-visible-sm  { visibility: visible !important; } .k6-invisible-sm  { visibility: hidden !important; } }
@media( min-width:  768px ) { .k6-visible-md  { visibility: visible !important; } .k6-invisible-md  { visibility: hidden !important; } }
@media( min-width:  992px ) { .k6-visible-lg  { visibility: visible !important; } .k6-invisible-lg  { visibility: hidden !important; } }
@media( min-width: 1200px ) { .k6-visible-xl  { visibility: visible !important; } .k6-invisible-xl  { visibility: hidden !important; } }
@media( min-width: 1400px ) { .k6-visible-xxl { visibility: visible !important; } .k6-invisible-xxl { visibility: hidden !important; } }

.k6-overflow-x-auto    { overflow-x: auto   ; } .k6-overflow-y-auto    { overflow-y: auto   ; }
.k6-overflow-x-hidden  { overflow-x: hidden ; } .k6-overflow-y-hidden  { overflow-y: hidden ; }
.k6-overflow-x-visible { overflow-x: visible; } .k6-overflow-y-visible { overflow-y: visible; }
.k6-overflow-x-scroll  { overflow-x: scroll ; } .k6-overflow-y-scroll  { overflow-y: scroll ; }

/* ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== MARGIN PADDING */

.k6-m-n1 { margin: -.25rem; } .k6-mx-n1 { margin-left: -.25rem; margin-right: -.25rem; } .k6-my-n1 { margin-top: -.25rem; margin-bottom: -.25rem; } .k6-mt-n1 { margin-top: -.25rem; } .k6-me-n1 { margin-right: -.25rem; } .k6-mb-n1 { margin-bottom: -.25rem; } .k6-ms-n1 { margin-left: -.25rem; }
.k6-m-n2 { margin:  -.5rem; } .k6-mx-n2 { margin-left:  -.5rem; margin-right:  -.5rem; } .k6-my-n2 { margin-top:  -.5rem; margin-bottom:  -.5rem; } .k6-mt-n2 { margin-top:  -.5rem; } .k6-me-n2 { margin-right:  -.5rem; } .k6-mb-n2 { margin-bottom:  -.5rem; } .k6-ms-n2 { margin-left:  -.5rem; }
.k6-m-n3 { margin:   -1rem; } .k6-mx-n3 { margin-left:   -1rem; margin-right:   -1rem; } .k6-my-n3 { margin-top:   -1rem; margin-bottom:   -1rem; } .k6-mt-n3 { margin-top:   -1rem; } .k6-me-n3 { margin-right:   -1rem; } .k6-mb-n3 { margin-bottom:   -1rem; } .k6-ms-n3 { margin-left:   -1rem; }

.k6-my-1px { margin-top: 1px; margin-bottom: 1px; } .k6-my-n1px { margin-top: -1px; margin-bottom: -1px; }
.k6-my-2px { margin-top: 2px; margin-bottom: 2px; } .k6-my-n2px { margin-top: -2px; margin-bottom: -2px; }
.k6-my-3px { margin-top: 3px; margin-bottom: 3px; } .k6-my-n3px { margin-top: -3px; margin-bottom: -3px; } /* 38 (form-control) - 3 - 3 = 32 */

/* ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== WIDTH HEIGHT */

.k6-w-1rem { width :  1rem; } .k6-iw-1rem { min-width :  1rem; } .k6-aw-1rem { max-width :  1rem; } .k6-w-1p5rem { width : 1.5rem; } .k6-iw-1p5rem { min-width : 1.5rem; } .k6-aw-1p5rem { max-width : 1.5rem; }
.k6-w-2rem { width :  2rem; } .k6-iw-2rem { min-width :  2rem; } .k6-aw-2rem { max-width :  2rem; } .k6-w-2p5rem { width : 2.5rem; } .k6-iw-2p5rem { min-width : 2.5rem; } .k6-aw-2p5rem { max-width : 2.5rem; }
.k6-w-3rem { width :  3rem; } .k6-iw-3rem { min-width :  3rem; } .k6-aw-3rem { max-width :  3rem; } .k6-w-3p5rem { width : 3.5rem; } .k6-iw-3p5rem { min-width : 3.5rem; } .k6-aw-3p5rem { max-width : 3.5rem; }
.k6-w-4rem { width :  4rem; } .k6-iw-4rem { min-width :  4rem; } .k6-aw-4rem { max-width :  4rem; } .k6-w-4p5rem { width : 4.5rem; } .k6-iw-4p5rem { min-width : 4.5rem; } .k6-aw-4p5rem { max-width : 4.5rem; }

.k6-h-1rem { height:  1rem; } .k6-ih-1rem { min-height:  1rem; } .k6-ah-1rem { max-height:  1rem; } .k6-h-1p5rem { height: 1.5rem; } .k6-ih-1p5rem { min-height: 1.5rem; } .k6-ah-1p5rem { max-height: 1.5rem; }
.k6-h-2rem { height:  2rem; } .k6-ih-2rem { min-height:  2rem; } .k6-ah-2rem { max-height:  2rem; } .k6-h-2p5rem { height: 2.5rem; } .k6-ih-2p5rem { min-height: 2.5rem; } .k6-ah-2p5rem { max-height: 2.5rem; }
.k6-h-3rem { height:  3rem; } .k6-ih-3rem { min-height:  3rem; } .k6-ah-3rem { max-height:  3rem; } .k6-h-3p5rem { height: 3.5rem; } .k6-ih-3p5rem { min-height: 3.5rem; } .k6-ah-3p5rem { max-height: 3.5rem; }
.k6-h-4rem { height:  4rem; } .k6-ih-4rem { min-height:  4rem; } .k6-ah-4rem { max-height:  4rem; } .k6-h-4p5rem { height: 4.5rem; } .k6-ih-4p5rem { min-height: 4.5rem; } .k6-ah-4p5rem { max-height: 4.5rem; }

.k6-w-25vw { width :  25vw; } .k6-iw-25vw { min-width :  25vw; } .k6-aw-25vw { max-width :  25vw; } .k6-h-25vh   { height:   25vw; } .k6-ih-25vh   { min-height :  25vw; } .k6-ah-25vh   { max-height :  25vw; }
.k6-w-50vw { width :  50vw; } .k6-iw-50vw { min-width :  50vw; } .k6-aw-50vw { max-width :  50vw; } .k6-h-50vh   { height:   50vw; } .k6-ih-50vh   { min-height :  50vw; } .k6-ah-50vh   { max-height :  50vw; }
.k6-w-75vw { width :  75vw; } .k6-iw-75vw { min-width :  75vw; } .k6-aw-75vw { max-width :  75vw; } .k6-h-75vh   { height:   75vw; } .k6-ih-75vh   { min-height :  75vw; } .k6-ah-75vh   { max-height :  75vw; }

.k6-w-sm   { width:  576px; } .k6-iw-sm   { min-width:  576px; } .k6-aw-sm   { max-width:  576px; }
.k6-w-md   { width:  768px; } .k6-iw-md   { min-width:  768px; } .k6-aw-md   { max-width:  768px; }
.k6-w-lg   { width:  992px; } .k6-iw-lg   { min-width:  992px; } .k6-aw-lg   { max-width:  992px; }
.k6-w-xl   { width: 1200px; } .k6-iw-xl   { min-width: 1200px; } .k6-aw-xl   { max-width: 1200px; }
.k6-w-xxl  { width: 1400px; } .k6-iw-xxl  { min-width: 1400px; } .k6-aw-xxl  { max-width: 1400px; }

/* ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== FONT TEXT LINK */

.k6-fs-8px    { font-size:   8px ; } .k6-fs-9px  { font-size:   9px ; } .k6-fs-10px   { font-size:  10px ; } .k6-fs-11px { font-size: 11px ; }
.k6-fs-12px   { font-size:  12px ; } .k6-fs-13px { font-size:  13px ; } .k6-fs-14px   { font-size:  14px ; } .k6-fs-15px { font-size: 15px ; }
.k6-fs-16px   { font-size:  16px ; } .k6-fs-17px { font-size:  17px;  } .k6-fs-18px   { font-size:  18px ; } .k6-fs-19px { font-size: 19px;  }
.k6-fs-0p5rem { font-size:  .5rem; } .k6-fs-1rem { font-size:   1rem; } .k6-fs-1p5rem { font-size: 1.5rem; } .k6-fs-2rem { font-size:  2rem; }

.k6-text-primary-hover:hover   { color: var( --bs-primary   ) !important; } .k6-link-primary   { color: rgba( var( --bs-primary-rgb   ), 1 ) !important; } .k6-link-primary:hover   { color: rgba( var( --bs-primary-rgb   ), .5 ) !important; }
.k6-text-secondary-hover:hover { color: var( --bs-secondary ) !important; } .k6-link-secondary { color: rgba( var( --bs-secondary-rgb ), 1 ) !important; } .k6-link-secondary:hover { color: rgba( var( --bs-secondary-rgb ), .5 ) !important; }
.k6-text-success-hover:hover   { color: var( --bs-success   ) !important; } .k6-link-success   { color: rgba( var( --bs-success-rgb   ), 1 ) !important; } .k6-link-success:hover   { color: rgba( var( --bs-success-rgb   ), .5 ) !important; }
.k6-text-danger-hover:hover    { color: var( --bs-danger    ) !important; } .k6-link-danger    { color: rgba( var( --bs-danger-rgb    ), 1 ) !important; } .k6-link-danger:hover    { color: rgba( var( --bs-danger-rgb    ), .5 ) !important; }
.k6-text-warning-hover:hover   { color: var( --bs-warning   ) !important; } .k6-link-warning   { color: rgba( var( --bs-warning-rgb   ), 1 ) !important; } .k6-link-warning:hover   { color: rgba( var( --bs-warning-rgb   ), .5 ) !important; }
.k6-text-info-hover:hover      { color: var( --bs-info      ) !important; } .k6-link-info      { color: rgba( var( --bs-info-rgb      ), 1 ) !important; } .k6-link-info:hover      { color: rgba( var( --bs-info-rgb      ), .5 ) !important; }
.k6-text-light-hover:hover     { color: var( --bs-light     ) !important; } .k6-link-light     { color: rgba( var( --bs-light-rgb     ), 1 ) !important; } .k6-link-light:hover     { color: rgba( var( --bs-light-rgb     ), .5 ) !important; }
.k6-text-dark-hover:hover      { color: var( --bs-dark      ) !important; } .k6-link-dark      { color: rgba( var( --bs-dark-rgb      ), 1 ) !important; } .k6-link-dark:hover      { color: rgba( var( --bs-dark-rgb      ), .5 ) !important; }
.k6-text-body-hover:hover      { color: var( --bs-body      ) !important; }
.k6-text-muted-hover:hover     { color: var( --bs-muted     ) !important; }
.k6-text-white-hover:hover     { color: var( --bs-white     ) !important; }
.k6-text-black-50-hover:hover  { color: var( --bs-black-50  ) !important; }
.k6-text-white-50-hover:hover  { color: var( --bs-white-50  ) !important; }

.k6-text-truncate-1 { overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; }
.k6-text-truncate-2 { overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; }
.k6-text-truncate-3 { overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 3; }
.k6-text-truncate-4 { overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 4; }
.k6-text-truncate-5 { overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 5; }
.k6-text-truncate-6 { overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 6; }
.k6-text-truncate-7 { overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 7; }
.k6-text-truncate-8 { overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 8; }

/* ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== LIST */

.k6-ul        { padding-left:   0   ; list-style:   none; } .k6-ul ul     { padding-left: 0   ; list-style:   none; }
.k6-ul-2-0 li { margin-left : .25rem; margin-top:   0   ; } .k6-ul-3-0 li { margin-left : 1rem; margin-top:   0   ; } .k6-ul-4-0  li { margin-left: 1.5rem; margin-top:   0   ; }
.k6-ul-2-1 li { margin-left : .25rem; margin-top: .25rem; } .k6-ul-3-1 li { margin-left : 1rem; margin-top: .25rem; } .k6-ul-4-1  li { margin-left: 1.5rem; margin-top: .25rem; }
.k6-ul-2-2 li { margin-left : .25rem; margin-top:  .5rem; } .k6-ul-3-2 li { margin-left : 1rem; margin-top:  .5rem; } .k6-ul-4-2  li { margin-left: 1.5rem; margin-top:  .5rem; }
.k6-ul-2-3 li { margin-left : .25rem; margin-top:   1rem; } .k6-ul-3-3 li { margin-left : 1rem; margin-top:   1rem; } .k6-ul-4-3  li { margin-left: 1.5rem; margin-top:   1rem; }

/* ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== CURSOR */

.k6-c-alias     { cursor: alias    ; } .k6-c-all-scroll { cursor: all-scroll; } .k6-c-auto        { cursor: auto       ; } .k6-c-cell        { cursor: cell       ; } .k6-c-context-menu { cursor: context-menu; } .k6-c-col-resize { cursor: col-resize; }.k6-c-copy        { cursor: copy       ; }
.k6-c-crosshair { cursor: crosshair; } .k6-c-default    { cursor: default   ; } .k6-c-e-resize    { cursor: e-resize   ; } .k6-c-ew-resize   { cursor: ew-resize  ; } .k6-c-grab         { cursor: grab        ; } .k6-c-grabbing   { cursor: grabbing  ; }.k6-c-help        { cursor: help       ; }
.k6-c-move      { cursor: move     ; } .k6-c-n-resize   { cursor: n-resize  ; } .k6-c-ne-resize   { cursor: ne-resize  ; } .k6-c-nesw-resize { cursor: nesw-resize; } .k6-c-ns-resize    { cursor: ns-resize   ; } .k6-c-nw-resize  { cursor: nw-resize ; }.k6-c-nwse-resize { cursor: nwse-resize; }
.k6-c-no-drop   { cursor: no-drop  ; } .k6-c-none       { cursor: none      ; } .k6-c-not-allowed { cursor: not-allowed; } .k6-c-pointer     { cursor: pointer    ; } .k6-c-progress     { cursor: progress    ; } .k6-c-row-resize { cursor: row-resize; }.k6-c-s-resize    { cursor: s-resize   ; }
.k6-c-se-resize { cursor: se-resize; } .k6-c-sw-resize  { cursor: sw-resize ; } .k6-c-text        { cursor: text       ; } .k6-c-w-resize    { cursor: w-resize   ; } .k6-c-wait         { cursor: wait        ; } .k6-c-zoom-in    { cursor: zoom-in   ; }.k6-c-zoom-out    { cursor: zoom-out   ; } /* .k6-c-url { cursor: url( ), auto; } */

/* ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== BUTTON */

.k6-btn-primary   { background-color: var( --bs-primary   ); border-color: var( --bs-primary   ); } .k6-btn-primary:hover   { background-color: rgba( var( --bs-primary-rgb   ), .5 ); border-color: rgba( var( --bs-primary-rgb   ), .5 ); }
.k6-btn-secondary { background-color: var( --bs-secondary ); border-color: var( --bs-secondary ); } .k6-btn-secondary:hover { background-color: rgba( var( --bs-secondary-rgb ), .5 ); border-color: rgba( var( --bs-secondary-rgb ), .5 ); }
.k6-btn-success   { background-color: var( --bs-success   ); border-color: var( --bs-success   ); } .k6-btn-success:hover   { background-color: rgba( var( --bs-success-rgb   ), .5 ); border-color: rgba( var( --bs-success-rgb   ), .5 ); }
.k6-btn-danger    { background-color: var( --bs-danger    ); border-color: var( --bs-danger    ); } .k6-btn-danger:hover    { background-color: rgba( var( --bs-danger-rgb    ), .5 ); border-color: rgba( var( --bs-danger-rgb    ), .5 ); }
.k6-btn-warning   { background-color: var( --bs-warning   ); border-color: var( --bs-warning   ); } .k6-btn-warning:hover   { background-color: rgba( var( --bs-warning-rgb   ), .5 ); border-color: rgba( var( --bs-warning-rgb   ), .5 ); }
.k6-btn-info      { background-color: var( --bs-info      ); border-color: var( --bs-info      ); } .k6-btn-info:hover      { background-color: rgba( var( --bs-info-rgb      ), .5 ); border-color: rgba( var( --bs-info-rgb      ), .5 ); }
.k6-btn-light     { background-color: var( --bs-light     ); border-color: var( --bs-light     ); } .k6-btn-light:hover     { background-color: rgba( var( --bs-light-rgb     ), .5 ); border-color: rgba( var( --bs-light-rgb     ), .5 ); }
.k6-btn-dark      { background-color: var( --bs-dark      ); border-color: var( --bs-dark      ); } .k6-btn-dark:hover      { background-color: rgba( var( --bs-dark-rgb      ), .5 ); border-color: rgba( var( --bs-dark-rgb      ), .5 ); }
.k6-btn-link      {            color: var( --bs-primary   );                                      } .k6-btn-link:hover      {            color: rgba( var( --bs-primary-rgb   ), .5 );                                                      }

.k6-btn-outline-primary   { color: var( --bs-primary   ); border-color: var( --bs-primary   ); } .k6-btn-outline-primary:hover   { color: #fff; background-color: var( --bs-primary   ); border-color: var( --bs-primary   ); }
.k6-btn-outline-secondary { color: var( --bs-secondary ); border-color: var( --bs-secondary ); } .k6-btn-outline-secondary:hover { color: #fff; background-color: var( --bs-secondary ); border-color: var( --bs-secondary ); }
.k6-btn-outline-success   { color: var( --bs-success   ); border-color: var( --bs-success   ); } .k6-btn-outline-success:hover   { color: #fff; background-color: var( --bs-success   ); border-color: var( --bs-success   ); }
.k6-btn-outline-danger    { color: var( --bs-danger    ); border-color: var( --bs-danger    ); } .k6-btn-outline-danger:hover    { color: #fff; background-color: var( --bs-danger    ); border-color: var( --bs-danger    ); }
.k6-btn-outline-warning   { color: var( --bs-warning   ); border-color: var( --bs-warning   ); } .k6-btn-outline-warning:hover   { color: #fff; background-color: var( --bs-warning   ); border-color: var( --bs-warning   ); }
.k6-btn-outline-info      { color: var( --bs-info      ); border-color: var( --bs-info      ); } .k6-btn-outline-info:hover      { color: #fff; background-color: var( --bs-info      ); border-color: var( --bs-info      ); }
.k6-btn-outline-light     { color: var( --bs-light     ); border-color: var( --bs-light     ); } .k6-btn-outline-light:hover     { color: #fff; background-color: var( --bs-light     ); border-color: var( --bs-light     ); }
.k6-btn-outline-dark      { color: var( --bs-dark      ); border-color: var( --bs-dark      ); } .k6-btn-outline-dark:hover      { color: #fff; background-color: var( --bs-dark      ); border-color: var( --bs-dark      ); }

           .k6-btn-li[aria-expanded="true"]::after { transform: rotate( 90deg ); }
           .k6-btn-li::after                       { transform-origin: .5em 50%; transition: none; position: absolute; right: 0; width: 1.25em; line-height: 0; content: url( "data:image/svg+xml, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28   0,   0,   0, .1 %29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e" ); }
.text-light.k6-btn-li::after                       {                                                                                                            content: url( "data:image/svg+xml, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28 255, 255, 255, .1 %29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e" ); }

.k6-btn-group-dropdown                                       { z-index: 1000; display: inline-block; } /* 버튼 그룹 내 드롭다운 버튼 포커스 쉐도우 z-index 오류 수정 */
.k6-btn-group-dropdown:focus-within + .k6-btn-group-dropdown { z-index:    0; }

/* ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== DROPDOWN */

@media( min-width:  576px ) { .k6-dropdown-toggle-sm  { white-space: nowrap; } .k6-dropdown-toggle-sm::after  { display: inline-block; margin-left: 0.255em; vertical-align: 0.255em; content: ""; border-top: 0.3em solid; border-right: 0.3em solid transparent; border-bottom: 0; border-left: 0.3em solid transparent; } }
@media( min-width:  768px ) { .k6-dropdown-toggle-md  { white-space: nowrap; } .k6-dropdown-toggle-md::after  { display: inline-block; margin-left: 0.255em; vertical-align: 0.255em; content: ""; border-top: 0.3em solid; border-right: 0.3em solid transparent; border-bottom: 0; border-left: 0.3em solid transparent; } }
@media( min-width:  992px ) { .k6-dropdown-toggle-lg  { white-space: nowrap; } .k6-dropdown-toggle-lg::after  { display: inline-block; margin-left: 0.255em; vertical-align: 0.255em; content: ""; border-top: 0.3em solid; border-right: 0.3em solid transparent; border-bottom: 0; border-left: 0.3em solid transparent; } }
@media( min-width: 1200px ) { .k6-dropdown-toggle-xl  { white-space: nowrap; } .k6-dropdown-toggle-xl::after  { display: inline-block; margin-left: 0.255em; vertical-align: 0.255em; content: ""; border-top: 0.3em solid; border-right: 0.3em solid transparent; border-bottom: 0; border-left: 0.3em solid transparent; } }
@media( min-width: 1400px ) { .k6-dropdown-toggle-xxl { white-space: nowrap; } .k6-dropdown-toggle-xxl::after { display: inline-block; margin-left: 0.255em; vertical-align: 0.255em; content: ""; border-top: 0.3em solid; border-right: 0.3em solid transparent; border-bottom: 0; border-left: 0.3em solid transparent; } }

/* ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== OPACITY */

.k6-opacity-0-hover:hover   { opacity: 0   !important; }
.k6-opacity-25-hover:hover  { opacity: .25 !important; }
.k6-opacity-50-hover:hover  { opacity: .5  !important; }
.k6-opacity-75-hover:hover  { opacity: .75 !important; }
.k6-opacity-100-hover:hover { opacity: 1   !important; }

/* ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== BACKGROUND BORDER OUTLINE */

.k6-bg-primary-hover:hover     { background-color: var( --bs-primary     ) !important; } .k6-border-primary   { border-color: var( --bs-primary   ) !important; } .k6-border-gray-100 { border-color: var( --bs-gray-100 ) !important; }
.k6-bg-secondary-hover:hover   { background-color: var( --bs-secondary   ) !important; } .k6-border-secondary { border-color: var( --bs-secondary ) !important; } .k6-border-gray-200 { border-color: var( --bs-gray-200 ) !important; }
.k6-bg-success-hover:hover     { background-color: var( --bs-success     ) !important; } .k6-border-success   { border-color: var( --bs-success   ) !important; } .k6-border-gray-300 { border-color: var( --bs-gray-300 ) !important; }
.k6-bg-danger-hover:hover      { background-color: var( --bs-danger      ) !important; } .k6-border-danger    { border-color: var( --bs-danger    ) !important; } .k6-border-gray-400 { border-color: var( --bs-gray-400 ) !important; }
.k6-bg-warning-hover:hover     { background-color: var( --bs-warning     ) !important; } .k6-border-warning   { border-color: var( --bs-warning   ) !important; } .k6-border-gray-500 { border-color: var( --bs-gray-500 ) !important; }
.k6-bg-info-hover:hover        { background-color: var( --bs-info        ) !important; } .k6-border-info      { border-color: var( --bs-info      ) !important; } .k6-border-gray-600 { border-color: var( --bs-gray-600 ) !important; }
.k6-bg-light-hover:hover       { background-color: var( --bs-light       ) !important; } .k6-border-light     { border-color: var( --bs-light     ) !important; } .k6-border-gray-700 { border-color: var( --bs-gray-700 ) !important; }
.k6-bg-dark-hover:hover        { background-color: var( --bs-dark        ) !important; } .k6-border-dark      { border-color: var( --bs-dark      ) !important; } .k6-border-gray-800 { border-color: var( --bs-gray-800 ) !important; }
.k6-bg-transparent-hover:hover { background-color: transparent             !important; }                                                                          .k6-border-gray-900 { border-color: var( --bs-gray-900 ) !important; }

.k6-bg-h111 { background-color: #111; } .k6-bg-h222 { background-color: #222; } .k6-bg-h333 { background-color: #333; } .k6-bg-h444 { background-color: #444; } .k6-bg-h555 { background-color: #555; } .k6-bg-h666 { background-color: #666; } .k6-bg-h777 { background-color: #777; }
.k6-bg-h888 { background-color: #888; } .k6-bg-h999 { background-color: #999; } .k6-bg-haaa { background-color: #AAA; } .k6-bg-hbbb { background-color: #BBB; } .k6-bg-hccc { background-color: #CCC; } .k6-bg-hddd { background-color: #DDD; } .k6-bg-heee { background-color: #EEE; }

.k6-bg-img { background-size: cover; background-position: center; }

.k6-rounded-tl { border-top-left-radius    : .25rem; } .k6-rounded-tl-0 { border-top-left-radius    : 0; }
.k6-rounded-tr { border-top-right-radius   : .25rem; } .k6-rounded-tr-0 { border-top-right-radius   : 0; }
.k6-rounded-bl { border-bottom-left-radius : .25rem; } .k6-rounded-bl-0 { border-bottom-left-radius : 0; }
.k6-rounded-br { border-bottom-right-radius: .25rem; } .k6-rounded-br-0 { border-bottom-right-radius: 0; }

.k6-outline-0 { outline: 0; }

/* ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== Z-INDEX */

.k6-z-dropdown           { z-index: 1000 !important; } .k6-z-n4 { z-index: -4 !important; }
.k6-z-sticky             { z-index: 1020 !important; } .k6-z-n3 { z-index: -3 !important; }
.k6-z-fixed              { z-index: 1030 !important; } .k6-z-n2 { z-index: -2 !important; }
.k6-z-offcanvas-backdrop { z-index: 1040 !important; } .k6-z-n1 { z-index: -1 !important; }
.k6-z-offcanvas          { z-index: 1045 !important; } .k6-z-0  { z-index:  0 !important; }
.k6-z-modal-backdrop     { z-index: 1050 !important; } .k6-z-1  { z-index:  1 !important; } .k6-z-1-focus:focus { z-index: 1 !important; }
.k6-z-modal              { z-index: 1055 !important; } .k6-z-2  { z-index:  2 !important; }
.k6-z-popover            { z-index: 1070 !important; } .k6-z-3  { z-index:  3 !important; }
.k6-z-tooltip            { z-index: 1080 !important; } .k6-z-4  { z-index:  4 !important; }

/* ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ANIMATION */

                              .k6-transform-none     { transform: none !important; }
@media( min-width:  576px ) { .k6-transform-none-sm  { transform: none !important; } }
@media( min-width:  768px ) { .k6-transform-none-md  { transform: none !important; } }
@media( min-width:  992px ) { .k6-transform-none-lg  { transform: none !important; } }
@media( min-width: 1200px ) { .k6-transform-none-xl  { transform: none !important; } }
@media( min-width: 1400px ) { .k6-transform-none-xxl { transform: none !important; } }

.k6-transition-none            { transition: none !important; }
.k6-transition-none.collapsing { display   : none !important; } /* 클래스 collapsing 순간 떨림 방지 */

/* ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ARTICLE */

.k6-article h1,
.k6-article h2 { padding-bottom: .5rem; border-bottom: 1px solid rgb( var( --bs-secondary-rgb ), .25 ); }

.k6-article h1,
.k6-article h2,
.k6-article h3,
.k6-article h4,
.k6-article h5,
.k6-article h6             { margin-top: 1.5rem; margin-bottom: 1rem; line-height: 1.5; font-weight: bold; position: relative; }
.k6-article h1:first-child { margin-top: 0     ; }

.k6-article h1 .k6-hash-target,
.k6-article h2 .k6-hash-target,
.k6-article h3 .k6-hash-target,
.k6-article h4 .k6-hash-target,
.k6-article h5 .k6-hash-target,
.k6-article h6 .k6-hash-target { position: absolute; top: calc( -1 * 5rem ); visibility: hidden; } /* https://css-tricks.com/hash-tag-links-padding */

.k6-article li:not( :last-child ) { margin-bottom: .5rem; }

.k6-article img { max-width: 100%; }

.k6-article a       { color: rgba( var( --bs-primary-rgb   ), 1  ); }
.k6-article a:hover { color: rgba( var( --bs-primary-rgb   ), .5 ); }

.k6-article blockquote { overflow: auto; }
.k6-article pre        { overflow: auto; }

.k6-article pre      { background-color: rgb( var( --bs-secondary-rgb ), .1 ); padding: 1rem; border-radius: .25rem; }
.k6-article pre code { color           :      var( --bs-pink                );                                       }

/* ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ARTICLE DARK */

.k6-article.bg-dark table    { color: var( --bs-light ); }
.k6-article.bg-dark pre code { color: var( --bs-teal  ); }

/* ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== HASH */

                    .k6-hash-link { display: none  ; }
.k6-hash-area:hover .k6-hash-link { display: inline; }
